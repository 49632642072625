'use client';

import {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {FreeTierPlaceholder} from '~/app/(unauthenticated)/job-board/_/components/job-board-listing/free-tier-placeholder';
import {JobBoardListingHeader} from '~/app/(unauthenticated)/job-board/_/components/job-board-listing/header';
import {JobBoardListingLabels} from '~/app/(unauthenticated)/job-board/_/components/job-board-listing/labels';
import {useJobBoardFilter} from '~/app/(unauthenticated)/job-board/_/hooks/use-job-board-filter';
import {PATHS} from '~/shared/constants/paths';
import {useJobBoardJobs} from '~/shared/data/job-board';
import {useSubscription} from '~/shared/data/subscription';
import {JobBoardFilterContext} from '~/shared/providers/job-board-filter-provider';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';
import {Card, DataTable, Empty, TextButton} from '@job-ish/ui/components';
import {IconAdjustmentsSearch, IconExclamationCircle} from '@tabler/icons-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {useParams, useRouter} from 'next/navigation';
import {v4 as uuid} from 'uuid';

import type {JobRead} from '@job-ish/job-data-api/client/job-data-api';
import type {DataTableColumn} from '@job-ish/ui/components';
import type {Dispatch, SetStateAction} from 'react';
import {JobBoardLoading} from './loading';

dayjs.extend(relativeTime);

export type JobBoardProps = {
	persistedFilter: JobBoardFilterConfig;
};

export const JobBoard = ({persistedFilter}: JobBoardProps) => {
	const {filter, updatePagination} = useJobBoardFilter();
	const [uniqueKey, setUniqueKey] = useState(uuid());
	const {loading} = useContext(JobBoardFilterContext);
	const {data: subscription} = useSubscription();

	const pagination = useMemo(
		() => ({
			pageIndex: filter.page === undefined ? 0 : filter.page - 1,
			pageSize: filter.pageSize ?? 20,
		}),
		[filter.page, filter.pageSize],
	);

	useEffect(() => {
		setUniqueKey(uuid());
	}, [filter]);

	const router = useRouter();
	const params = useParams();

	const jobId = Number(params.id);
	const styleRef = useRef<HTMLStyleElement>(null);

	useEffect(() => {
		if (params.id && styleRef.current) {
			styleRef.current.innerHTML = `
        #job-${jobId} {
          background-color: var(--mauve-4);
        }
      `;
		}
	}, [jobId, params.id]);

	const {data: jobBoardJobs, isLoading, isError} = useJobBoardJobs(persistedFilter);

	const filteredJobCount = useMemo(
		() => (jobBoardJobs?.data.count ? Math.floor((jobBoardJobs?.data.count + 4) / 5) : 0),
		[jobBoardJobs?.data.count],
	);

	const handlePaginationChange = useCallback(
		(
			state: (
				prevState: Readonly<{pageIndex: number; pageSize: number}>,
			) => Partial<{pageIndex: number; pageSize: number}> | null,
			callback?: () => void,
		) => {
			const prevState = {pageIndex: pagination.pageIndex, pageSize: pagination.pageSize};
			const newState = state(prevState);

			if (newState !== null) {
				updatePagination({
					pageIndex: newState.pageIndex === undefined ? 0 : newState.pageIndex + 1,
					pageSize: newState.pageSize ?? 30,
				});
			}

			callback?.();
		},
		[pagination.pageIndex, pagination.pageSize, updatePagination],
	) as Dispatch<SetStateAction<typeof pagination>>;

	const columns = useMemo<DataTableColumn<JobRead>[]>(
		() => [
			{
				id: 'Job Board Listing',
				cell: ({row: {original: job, index}}) => (
					<>
						{index % 5 === 1 && subscription?.status !== 'active' && (
							<FreeTierPlaceholder filteredCount={filteredJobCount} />
						)}
						<Card
							bordered
							className="w-full cursor-pointer rounded-none transition-colors hover:bg-mauve4 focus:bg-mauve5"
							id={`job-${job.id}`}
							key={job.id}
							onClick={() => router.push(`${PATHS.JobBoard}/${job.id}`)}
							shadow
						>
							<JobBoardListingHeader bookmarkIconOnly job={job} />
							<Card.Body className="w-full overflow-hidden">
								<JobBoardListingLabels job={job} />
							</Card.Body>
						</Card>
					</>
				),
			},
		],
		[filteredJobCount, router, subscription?.status],
	);

	const showLoadingSkeleton = useMemo(() => isLoading || loading, [isLoading, loading]);

	if (showLoadingSkeleton) {
		return <JobBoardLoading />;
	}

	if (isError || jobBoardJobs?.status !== 200) {
		return (
			<div className="h-full w-full p-[1px]">
				<Card bordered className="h-full w-full">
					<Empty
						className="h-full w-full"
						icon={IconExclamationCircle}
						size="lg"
						subtitle="Something went wrong while fetching jobs from the server. Please try again later."
						title="Error Loading Jobs"
					>
						<TextButton color="primary" onPress={() => router.refresh()}>
							Try Again
						</TextButton>
					</Empty>
				</Card>
			</div>
		);
	}

	return (
		<>
			<style ref={styleRef}>
				{`#job-${jobId} {
          background-color: var(--mauve-4);
        }`}
			</style>
			<DataTable
				className={clsx(
					'h-full flex-shrink flex-grow rounded-sm border border-mauve6 [&&>div:first-child]:overflow-x-hidden [&>div:first-child]:flex-grow [&>div:first-child]:rounded-sm [&>div:first-child]:border-none [&>div:nth-child(2)]:overflow-x-hidden [&>div]:overscroll-y-contain [&_table]:table-fixed [&_td]:p-0',
					jobBoardJobs.data.results.length === 0 && '[&_table]:h-full',
					params.id ? 'hidden lg:flex' : '',
				)}
				columns={columns}
				data={jobBoardJobs?.data.results ?? []}
				disableHeader
				disableViewOptions
				emptyIcon={IconAdjustmentsSearch}
				emptyText="No jobs found for the selected filters. Please try adjusting your search criteria."
				emptyTitle="No Jobs Found"
				key={uniqueKey}
				loading={showLoadingSkeleton}
				manualPagination
				onPaginationChange={handlePaginationChange}
				pagination={pagination}
				rowCount={jobBoardJobs?.data.count}
			/>
		</>
	);
};
