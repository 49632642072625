import {useId} from 'react';
import type {SubscriptionDialogProps} from '~/app/(unauthenticated)/job-board/_/components/subscription-dialog';
import {SubscriptionDialog} from '~/app/(unauthenticated)/job-board/_/components/subscription-dialog';
import {Avatar, Button, Card} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {IconCreditCard, IconLockAccess} from '@tabler/icons-react';

export type FreeTierPlaceholderProps = {
	filteredCount: number;
};

export const FreeTierPlaceholder = ({filteredCount}: FreeTierPlaceholderProps) => {
	const id = useId();
	const {show: showDialog} = useModal();

	return (
		<Card
			accent="primary"
			accentPosition="bottom"
			bordered
			className="w-full cursor-auto rounded-none"
			id={id}
			key={id}
			shadow
		>
			<div className="flex w-full items-start justify-start gap-2">
				<Avatar className="shrink-0 rounded-sm border border-mauve7" size="xl">
					<Avatar.Fallback>
						<IconLockAccess className="h-8 w-8" />
					</Avatar.Fallback>
				</Avatar>
				<div className="flex flex-col items-start overflow-hidden text-left text-mauve12">
					<Card.Title className="block w-full items-end gap-1.5 truncate text-sm leading-snug">
						<div className="truncate">Premium Job</div>
					</Card.Title>
					<Card.Description asChild>
						<div className="w-full whitespace-pre-wrap">
							Subscribe to get access to{' '}
							{filteredCount ? `${Intl.NumberFormat().format(filteredCount)} ` : ''}
							more jobs matching your search criteria.
						</div>
					</Card.Description>
				</div>
			</div>
			<Card.Body className="w-full overflow-hidden">
				<Button
					as="div"
					className="w-full max-w-full sm:w-72"
					iconLeft={IconCreditCard}
					onPress={() => showDialog<SubscriptionDialogProps>(SubscriptionDialog)}
					size="sm"
				>
					Upgrade to Premium
				</Button>
			</Card.Body>
		</Card>
	);
};
