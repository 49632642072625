'use client';

import {useMemo} from 'react';
import {useJobBoardFilter} from '~/app/(unauthenticated)/job-board/_/hooks/use-job-board-filter';
import {Avatar, Card, DataTable, Skeleton} from '@job-ish/ui/components';
import clsx from 'clsx';
import {useParams} from 'next/navigation';

import type {DataTableColumn} from '@job-ish/ui/components';

export const JobBoardLoading = () => {
	const {filter} = useJobBoardFilter();

	const pagination = useMemo(
		() => ({
			pageIndex: filter.page === undefined ? 0 : filter.page - 1,
			pageSize: filter.pageSize ?? 20,
		}),
		[filter.page, filter.pageSize],
	);

	const params = useParams();

	const loadingColumns = useMemo<DataTableColumn<{index: number}>[]>(
		() => [
			{
				id: 'Job Board Listing',
				cell: ({row: {original}}) => (
					<Card
						bordered
						className="w-full cursor-pointer rounded-none transition-colors hover:bg-mauve4 focus:bg-mauve5"
						key={original.index}
						role="row"
						shadow
					>
						<Card.Header className="w-full flex-col">
							<div className="flex w-full items-start justify-start gap-2">
								<Avatar className="shrink-0 rounded-sm border border-mauve7" size="xl">
									<Avatar.Fallback>
										<Skeleton className="h-full w-full rounded-none" />
									</Avatar.Fallback>
								</Avatar>
								<div className="flex flex-col items-start gap-1 overflow-hidden text-left">
									<Card.Title className="block w-full items-end gap-1.5 truncate leading-snug">
										<div className="truncate">
											<Skeleton className="h-5 w-72 max-w-full" />
										</div>
									</Card.Title>
									<Card.Description asChild>
										<div className="w-full">
											<Skeleton className="h-5 w-24" />
										</div>
									</Card.Description>
									<Skeleton className="h-3 w-16" />
								</div>
							</div>
							<Skeleton className="mt-1.5 h-4 w-72 self-start" />
						</Card.Header>
					</Card>
				),
			},
		],
		[],
	);

	const loadingData = useMemo(
		() => Array.from({length: pagination.pageSize}).map((_, index) => ({index})),
		[pagination.pageSize],
	);

	return (
		<DataTable
			className={clsx(
				'h-full flex-shrink flex-grow rounded-sm border border-mauve6 [&&>div:first-child]:overflow-x-hidden [&>div:first-child]:flex-grow [&>div:first-child]:rounded-sm [&>div:first-child]:border-none [&>div:nth-child(2)]:overflow-x-hidden [&>div]:overscroll-y-contain [&_table]:table-fixed [&_td]:p-0',
				params.id ? 'hidden lg:flex' : '',
			)}
			columns={loadingColumns}
			data={loadingData}
			disableHeader
			disableViewOptions
			loading
			manualPagination
			onPaginationChange={() => {}}
			pagination={pagination}
		/>
	);
};
