import {forwardRef} from 'react';
import assign from 'lodash.assign';
import {twMerge} from 'tailwind-merge';

import type {ComponentPropsWithoutRef} from 'react';

export type TableHeaderProps = ComponentPropsWithoutRef<'thead'>;
const TableHeader = forwardRef<HTMLTableSectionElement, TableHeaderProps>(
	({className, ...props}, forwardedRef) => (
		<thead
			className={twMerge('h-8 border-b border-mauve6 bg-mauve2 [&_tr]:border-b', className)}
			ref={forwardedRef}
			{...props}
		/>
	),
);

export type TableBodyProps = ComponentPropsWithoutRef<'tbody'>;
const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
	({className, ...props}, forwardedRef) => (
		<tbody className={twMerge('[&_tr:last-child]:border-0', className)} ref={forwardedRef} {...props} />
	),
);

export type TableFooterProps = ComponentPropsWithoutRef<'tfoot'>;
const TableFooter = forwardRef<HTMLTableSectionElement, TableFooterProps>(
	({className, ...props}, forwardedRef) => (
		<tfoot
			className={twMerge('bg-mauve2 font-medium text-mauve12', className)}
			ref={forwardedRef}
			{...props}
		/>
	),
);

export type TableRowProps = ComponentPropsWithoutRef<'tr'>;
const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(({className, ...props}, forwardedRef) => (
	<tr
		className={twMerge(
			'border-b border-mauve6 text-mauve12 transition-colors data-[state=selected]:bg-mauve5 hover:bg-mauve4',
			className,
		)}
		ref={forwardedRef}
		{...props}
	/>
));

export type TableHeadProps = ComponentPropsWithoutRef<'th'>;
const TableHead = forwardRef<HTMLTableCellElement, TableHeadProps>(({className, ...props}, forwardedRef) => (
	<th
		className={twMerge(
			'h-12 px-4 text-left align-middle font-medium text-mauve11 [&:has([role=checkbox])]:pr-0',
			className,
		)}
		ref={forwardedRef}
		{...props}
	/>
));

export type TableCellProps = ComponentPropsWithoutRef<'td'>;
const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(({className, ...props}, forwardedRef) => (
	<td
		className={twMerge('p-4 align-middle [&:has([role=checkbox])]:pr-0', className)}
		ref={forwardedRef}
		{...props}
	/>
));

export type TableCaptionProps = ComponentPropsWithoutRef<'caption'>;
const TableCaption = forwardRef<HTMLTableCaptionElement, TableCaptionProps>(
	({className, ...props}, forwardedRef) => (
		<caption className={twMerge('my-4 text-sm text-mauve11', className)} ref={forwardedRef} {...props} />
	),
);

export type TableProps = ComponentPropsWithoutRef<'table'>;
const Table = assign(
	forwardRef<HTMLTableElement, TableProps>(({className, ...props}, forwardedRef) => (
		<div
			className="max-h-full w-full overflow-auto rounded-md border border-mauve6 bg-mauve2"
			ref={forwardedRef}
		>
			<table className={twMerge('w-full caption-bottom text-sm', className)} {...props} />
		</div>
	)),
	{
		Header: TableHeader,
		Body: TableBody,
		Footer: TableFooter,
		Row: TableRow,
		Head: TableHead,
		Cell: TableCell,
		Caption: TableCaption,
	},
);

Table.displayName = 'Table';
Table.Header.displayName = 'Table.Header';
Table.Body.displayName = 'Table.Body';
Table.Footer.displayName = 'Table.Footer';
Table.Row.displayName = 'Table.Row';
Table.Head.displayName = 'Table.Head';
Table.Cell.displayName = 'Table.Cell';
Table.Caption.displayName = 'Table.Caption';

export default Table;
